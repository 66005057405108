
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import copy from "clipboard-copy";
import gql from "graphql-tag";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Card, Btn },
})
export default class LessonItem extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

  @Mutation showSuccess;
  @Prop()
  lesson;

  @Prop({ default: false })
  canJoinLesson;

  @Prop()
  index;

  @Prop()
  canEdit;

  formatDate(date) {
    return moment(date, "YYYY-MM-DD").format("dddd DD MMMM YYYY");
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  isTeacher(lesson) {
    return lesson.teachers.includes(this.user.guid);
  }

  async joinLesson(guid) {
    const lessonUrl = await this.$apollo.query({
      query: gql`
        query ($guid: String!) {
          EducationMeetingParticipateLesson(lesson_guid: $guid)
        }
      `,
      fetchPolicy: "no-cache",
      variables: {
        guid: guid,
      },
    });
    window.open(lessonUrl.data.EducationMeetingParticipateLesson);
  }

  async startLesson(guid) {
    const lessonUrl = await this.$apollo.query({
      query: gql`
        query ($guid: String!) {
          EducationMeetingStartLesson(lesson_guid: $guid)
        }
      `,
      fetchPolicy: "no-cache",
      variables: {
        guid: guid,
      },
    });
    window.open(lessonUrl.data.EducationMeetingStartLesson);
  }

  async copyStartLink(lesson) {
    copy(lesson.start_url);
    this.showSuccess("Start link copiato");
  }

  async copyJoinLink(lesson) {
    copy(lesson.join_url);
    this.showSuccess("Link lezione copiato");
  }
}
