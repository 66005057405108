
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import LessonItem from "@/components/LessonItem.vue";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import * as _ from "lodash";
import { CourseAvailability } from "@/utils/courses";
import moment from "moment";
import { formatDateFromApi } from "@/plugins/moment";
import { checkUserRole, RoleCodes } from "@/utils/roles";

@Component({
  components: { Card, Modal, LessonItem },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class CoursesCalendarView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Mutation showError;

  weekDays = [1, 2, 3, 4, 5, 6, 0];
  value = "";
  type = "month";
  types = [
    { value: "day", label: "Giorno" },
    { value: "week", label: "Settimana" },
    { value: "month", label: "Mese" },
  ];

  loading = false;

  lessons = [];

  startDate = moment().clone().startOf("month").format("YYYY-MM-DD");
  endDate = moment().clone().endOf("month").format("YYYY-MM-DD");
  month = null;

  modalKey = 1;
  showLessonModal = null;
  selectedLesson = null;

  async getLessons(datesData) {
    this.startDate = datesData.start.date;
    this.endDate = datesData.end.date;
    this.month = datesData.start.month;
    let lessonsRes;
    try {
      if (checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2])) {
        lessonsRes = await this.$apollo.query({
          query: require("@/graphql/getEducationCourseCalendar.graphql"),
          variables: {
            from: datesData.start.date,
            to: datesData.end.date,
          },
          fetchPolicy: "no-cache",
        });
        this.lessons = lessonsRes.data.EducationCourseCalendar;
      } else {
        lessonsRes = await this.$apollo.query({
          query: require("@/graphql/getEducationCourseCalendarByStudent.graphql"),
          variables: {
            studentGuid: this.user.guid,
            from: datesData.start.date,
            to: datesData.end.date,
          },
          fetchPolicy: "no-cache",
        });
        this.lessons = lessonsRes.data.EducationCourseCalendarByStudent;
      }
      this.lessons.forEach((lesson) => {
        lesson.color = "primary";
        lesson.timed = true;
      });
    } catch (error) {
      console.error(error);
    }
  }

  formatDate(date) {
    return formatDateFromApi(date, "dddd DD MMMM YYYY");
  }

  formatMonth() {
    return moment(this.startDate).format("MMMM YYYY");
  }

  getEventColor(event) {
    let hash = 0;
    for (
      let i = 0;
      i < event.course_guid.length;
      hash = event.course_guid.charCodeAt(i++) + ((hash << 5) - hash)
    );
    const color = Math.floor(
      Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)
    ).toString(16);
    return "#" + Array(6 - color.length + 1).join("0") + color;
  }

  async lessonClick(data) {
    this.loading = true;
    this.modalKey = Math.random();
    this.showLessonModal = true;

    const lessonData = data.event;
    const courseRes = await this.$apollo.query({
      query: require("@/graphql/getCourse.graphql"),
      variables: {
        courseGuid: lessonData.course_guid,
      },
      fetchPolicy: "no-cache",
    });
    if (courseRes.data) {
      this.selectedLesson = _.find(
        courseRes.data.EducationCourse.lessons,
        (l) => {
          return l.lesson_guid == lessonData.id;
        }
      );
      if (this.selectedLesson) {
        this.selectedLesson["courseName"] =
          courseRes.data.EducationCourse.course_title;

        this.selectedLesson["teachers"] = [];
        courseRes.data.EducationCourse.teachers?.forEach((teacher) => {
          if (this.selectedLesson.teacher_guid.includes(teacher.guid)) {
            this.selectedLesson["teachers"].push(teacher);
          }
        });
        this.selectedLesson["lesson_from"] = this.selectedLesson[
          "lesson_from"
        ].slice(0, -3);
        this.selectedLesson["lesson_to"] = this.selectedLesson[
          "lesson_to"
        ].slice(0, -3);

        this.loading = false;
      } else {
        this.showError("Lezione non trovata");
        this.showLessonModal = false;
      }
    } else {
      this.showError("Lezione non trovata");
      this.showLessonModal = false;
    }
  }

  openDay(data) {
    this.type = "day";
    this.startDate = data.date;
    this.endDate = data.date;
  }
}
